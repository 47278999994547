body {
  font-family: Arial, sans-serif;
  background: url("./Images/streets_background.gif") repeat;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
}

.App {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 90%; /* Use a percentage for responsiveness */
  width: 100%;
  text-align: center;
  margin: 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.transaction-list {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
}

.transaction {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  gap: 10px; /* Add gap for better spacing */
}

.transaction select,
.transaction input {
  margin: 0 10px;
  flex: 1 1 150px; /* Flex properties for responsiveness */
}

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  gap: 10px;
  margin-top: 20px;
}

.currency-selector {
  display: none;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo {
  max-width: 150px;
  margin-bottom: 10px;
}

.made-by {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.eth-donations {
  margin-bottom: 20px;
  font-size: 12px;
  color: #333;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

label {
  display: inline-block;
  margin-right: 10px;
}

select,
input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 10px;
  width: calc(100% - 20px); /* Full width minus padding */
  max-width: 200px; /* Maximum width for inputs */
  box-sizing: border-box; /* Include padding in width calculation */
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

h2 {
  margin-top: 20px;
  font-size: 20px;
}

footer {
  margin-top: 20px;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  font-size: 12px;
  line-height: 1.5;
}

footer a {
  color: #007bff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.disclaimer {
  font-size: 10px; /* Smaller font for the disclaimer */
  color: #777; /* Lighter color for less emphasis */
  margin-top: 10px;
  text-align: center;
}

.bets-list {
  margin-top: 20px;
  text-align: left;
}

.bets-list-content {
  max-height: 300px;
  overflow-y: auto;
}

.bet {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column; /* Stack bet info and button */
  justify-content: space-between;
  align-items: center;
}

.bet-info {
  width: 100%;
}

.bet h4,
.bet p {
  margin: 0;
}

.bet ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
}

.bet ul li {
  margin: 5px 0;
}

.remove-bet {
  background-color: #dc3545;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

.remove-bet:hover {
  background-color: #c82333;
}

.remove-all-bets {
  background-color: #dc3545;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin: 10px 0;
}

.remove-all-bets:hover {
  background-color: #c82333;
}

.total-hc {
  text-align: center;
  margin-top: 10px;
}

.bet-transaction {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bet-transaction img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.transaction-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  gap: 10px;
  margin-top: 10px;
}
